import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {HomeOutlined} from "@ant-design/icons";
import Statiscs from "./components/Statiscs/Statiscs";


import bottle_image from "../../assets/images/image-removebg-preview.png";

import {useSelector} from "react-redux";
import Activity from "./components/Activity/Activity";
import RecentSales from "./components/RecentSales/RecentSales";
import {getDrivers} from "../../api/service_drivers";
import {getCars} from "../../api/service_cars";
import {getDriverWarehouses} from "../../api/service_driver_warehouses";
import {getBottles} from "../../api/service_bottles";
import {getBottleTypes} from "../../api/service_bottlestypes";
import {Table} from "antd";
import {getOrdersTeen, getProductCount, getUSersCount} from "../../api/dashboard_api";


export default function DemoDashboard() {

    const [productCouts, setProductCount] = useState([]);
    const [usersCount, setUsersCount] = useState([]);
    const [ordersDashboard,setOrdersDashboard] = useState([])

    useEffect(() => {
        fetchOrderDashboard()
        fetchProductCount()
        fetchUsersCount()
    }, [])


    const fetchProductCount = async () => {
        try {
            const count = await getProductCount();
            setProductCount(count)
        } catch (e){
            console.log(e)
        }

    }
    const fetchUsersCount = async () => {
        try {
            const count = await getUSersCount();
            setUsersCount(count)
        } catch (e){
            console.log(e)
        }

    }
    const fetchOrderDashboard = async () => {
        try {
            const count = await getOrdersTeen();
            setOrdersDashboard(count)
        } catch (e){
            console.log(e)
        }

    }
    const theme = useSelector((state) => state.theme.theme);

    return <>

        <div className={styles.container}>
            <div className={` ${theme === `dark` ? `text-white` : `text-black`} ${styles.dash_header}`}>
                <div className={' text-2xl font-semibold'}>Default</div>
                <div className={' text-2xl font-semibold'}><HomeOutlined/> Dashboard</div>
            </div>

            <div className={styles.statistics}>

                <div
                    className={'max-[1090px]:w-full w-[35%] h-full flex p-6 bg-gradient-to-br from-blue-500 to-blue-950 rounded-3xl shadow-2xl relative'}>

                    <div className={'w-1/2 flex flex-col items-start gap-y-3 text-start'}>
                        <div className={'text-xl line-clamp-2 text-white font-semibold'}>
                            Oson express Boshqaruv paneliga xush kelibsiz
                        </div>

                        <div className={'text line-clamp-2 text-white font-semibold'}>
                            Buyerda Statistic ma`lumotlarni ko`rishingiz mumkin
                        </div>

                        <div className={'px-4 py-1 text text-white mt-2 border-2 border-white rounded-2xl'}>
                            Yangiliklarni kuzatib boring
                        </div>
                    </div>

                    <img src={bottle_image}
                         className={' select-none absolute bottom-0 right-0 w-fit h-52 object-contain'}/>

                </div>

                <div
                    className={' max-[1090px]:h-64 max-[1090px]:w-full w-[65%] h-full flex flex-col items-center gap-y-2 '}>

                    <div
                        className={'w-full max-[850px]:h-full h-[50%] gap-x-3 px-1 flex flex-row max-[850px]:flex-col '}>

                        <div className={' max-[850px]:w-full w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>

                            <Statiscs
                                count={productCouts}
                                title={'Maxsulotlar'}
                                percent={''}
                                color={'red'}
                                icon={'BsBoxFill'}
                            />

                        </div>

                        <div className={' max-[850px]:w-full w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>

                            <Statiscs
                                count={ordersDashboard.total_orders}
                                title={'Barcha buyurtmalar '}
                                percent={''}
                                color={'green'}
                                icon={'LuWarehouse'}
                            />

                        </div>

                        <div className={' max-[850px]:w-full w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>

                            {/*<LineGraph data={data} width={'95%'} height={110} />*/}

                            {/*<Apexcharts*/}
                            {/*    height={110}*/}
                            {/*/>*/}

                            <Statiscs
                                count={ordersDashboard.pending_orders}
                                title={'Qabul qilingan buyurtmalar'}
                                percent={''}
                                color={'green'}
                                icon={'CiMail'}
                            />

                        </div>

                    </div>

                    <div className={' w-full h-[50%] gap-x-3 px-1 flex flex-row'}>

                        <div className={'w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>

                            <Statiscs
                                count={ordersDashboard.on_hold_orders}
                                title={'qabul qilinmagan buyurtmalar'}
                                percent={''}
                                color={'blue'}
                                icon={'FaTshirt'}
                            />

                        </div>

                        <div className={'w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>
                            <Statiscs
                                count={ordersDashboard.completed_orders}
                                title={'Bajarilgan buyurtmalar'}
                                percent={''}
                                color={'brown'}
                                icon={'truck'}/>

                        </div>

                        <div className={'w-1/3 h-full rounded-2xl overflow-hidden shadow-2xl '}>

                            {/*<ColumnGraph count={10000} title={'Purchase'} STATS={STATS} />*/}

                            <Statiscs
                                count={usersCount}
                                title={'Foydalanuvchilar'}
                                percent={''}
                                color={'green'}
                                icon={'user'}
                            />

                        </div>

                    </div>

                </div>

            </div>

            {/*<div className={'w-full h-96 flex flex-row justify-between gap-x-3 pr-2'}>*/}
            {/*    <div className={`${theme===`dark`?`bg-gray-700`:`bg-white`} duration-500 w-[60%] h-full flex flex-row justify-between rounded-2xl p-6`}>*/}


            {/*        <div className={'w-[65%] h-full'}>*/}
            {/*            <ColumnGraphChart data={data} width={'95%'} height={320}/>*/}
            {/*        </div>*/}

            {/*        <div className={'w-[40%] h-full flex flex-col justify-around items-center px-1'}>*/}

            {/*            <CashStatiscs*/}
            {/*                title={'Income'}*/}
            {/*                total={4000}*/}
            {/*                cash={300}*/}
            {/*                icon={'MdOutlineAttachMoney'}*/}
            {/*            />*/}

            {/*            <CashStatiscs*/}
            {/*                title={'Expanse'}*/}
            {/*                total={4000}*/}
            {/*                cash={300}*/}
            {/*                icon={'FaHandHolding'}*/}
            {/*            />*/}

            {/*            <CashStatiscs*/}
            {/*                title={'Cashback'}*/}
            {/*                total={4000}*/}
            {/*                cash={300}*/}
            {/*                icon={'GiReceiveMoney'}*/}
            {/*            />*/}


            {/*        </div>*/}

            {/*    </div>*/}

            {/*    <div className={'w-[40%] h-full flex flex-col rounded-2xl overflow-hidden '}>*/}

            {/*        <ProfitStatistics*/}
            {/*            title={'Recent Orders'}*/}
            {/*            count={100}*/}
            {/*        />*/}

            {/*    </div>*/}

            {/*</div>*/}

            <div
                className={' max-[1090px]:w-full w-full h-full flex flex-col items-center overflow-hidden rounded-2xl'}>

                <RecentSales/>

            </div>
            <div
                className={'w-full h-96 max-[1090px]:h-[24rem] max-[1090px]:flex-wrap max-[1090px]:gap-y-3 flex items-start justify-between gap-x-2 pr-2 '}>

                <div
                    className={' max-[1090px]:w-full w-full h-full flex flex-col items-center overflow-hidden rounded-2xl '}>

                    <Activity/>

                </div>


                {/*<div className={'w-[33%] h-full flex flex-col items-center rounded-2xl overflow-hidden '}>*/}

                {/*    <TimeLineComponent/>*/}

                {/*</div>*/}


            </div>

            {/*<div className={'w-full flex items-center justify-between gap-x-2 h-96'}>*/}

            {/*    <div className={'w-[28%] h-full  flex flex-col justify-end items-center '}>*/}

            {/*        <ProAccount/>*/}

            {/*    </div>*/}


            {/*    <div className={'w-[32%] h-full flex flex-col items-center gap-y-4'}>*/}

            {/*        <div className={'w-full h-[35%] overflow-hidden rounded-2xl flex flex-col items-center'}>*/}
            {/*            <TotalUsers/>*/}
            {/*        </div>*/}

            {/*        <div className={'w-full h-[60%] overflow-hidden rounded-2xl '}>*/}

            {/*            <FollowerGrowth/>*/}

            {/*        </div>*/}


            {/*    </div>*/}


            {/*    <div className={'w-[38%] h-full overflow-hidden rounded-2xl flex flex-col items-center'}>*/}

            {/*        <PaperNote/>*/}

            {/*    </div>*/}

            {/*</div>*/}

            {/*<div className={' w-full pr-2'}>*/}
            {/*    <Table className={'w-full '} dataSource={''} columns={''} />*/}
            {/*</div>*/}


        </div>
    </>
}
