export function extractData(input) {
    input = input.replace(/\n/g, '').trim();

    const data = {
        title: [],
        badge: [],
        description: "",
        best_b_date: "",
        made_in: "",
        discount: "",
        minus: ""
    };

    // Регулярные выражения для каждого тега
    const regexPatterns = [
        { key: 'title', regex: /%t\s*([^%]*)\s*\//g },
        { key: 'badge', regex: /%b\s*([^%]*)\s*\//g },
        { key: 'best_b_date', regex: /%x\s*([^%]*)\s*\//g },
        { key: 'made_in', regex: /%m\s*([^%]*)\s*\//g },
        { key: 'discount', regex: /%\*disc\s*([^%]*)\s*\//g },
        { key: 'minus', regex: /%&min\s*([^%]*)\s*\//g }
    ];

    // Сначала выделяем и удаляем описание %d, чтобы оно не мешало другим тегам
    const descriptionMatch = input.match(/%d\s*([^%]*)\s*\//);
    if (descriptionMatch) {
        data.description = descriptionMatch[1].trim();
        input = input.replace(descriptionMatch[0], '').trim();  // Удаляем найденный тег из строки
    }

    // Обрабатываем остальные теги
    regexPatterns.forEach(({ key, regex }) => {
        let match;
        while ((match = regex.exec(input)) !== null) {
            if (key === 'title' || key === 'badge') {
                data[key].push(match[1].trim());
            } else {
                data[key] = match[1].trim();
            }
            // Удаляем найденный тег из строки
            input = input.replace(match[0], '').trim();
        }
    });

    return data;
}
