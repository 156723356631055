import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import prevIcon from "../../../../assets/icons/prevArrow.svg";
import nextIcon from "../../../../assets/icons/nextArrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { FaMinus } from "react-icons/fa";
import { CgMathPlus } from "react-icons/cg";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {extractData} from "../../../../utils";

export default function SelectedProductContent({ productData ,covers,topicons , name ,price }) {

    const [isActive, setActive] = useState(false);
    const [myCount, setMyCount] = useState(0);
    const [product, setProduct] = useState(null);

    const handleIncrement = () => {
        setMyCount(prevCount => prevCount + 1);
        setActive(true);
    };

    const handleDecrement = () => {
        if (myCount > 0) {
            setMyCount(prevCount => prevCount - 1);
        } else {
            setActive(false);
        }
    };
    useEffect(()=>{

        console.log(covers)
    },[covers])
    useEffect(() => {
        const processedProduct = extractData(productData);
        setProduct(processedProduct);
        console.log(productData)
    }, [productData]);

    if (!product) {
        return null; // Или можно отобразить индикатор загрузки
    }



    return (
        <div className={'w-full h-full flex items-start '}>

            {/* Левая сторона */}
            <div className={style.productWrapper}>
                <div className={'w-full h-full'}>
                    <div className={'h-auto  w-full flex flex-col items-start '}>
                        <div className={'w-full h-auto'}>

                            {/* Слайдер */}
                            <div className={'w-full h-96 flex items-center gap-3 relative rounded-xl overflow-hidden'}>
                                <button className={`swiper-button image-swiper-button-prev__ absolute z-10 `}>
                                    <img src={prevIcon} className={'w-fit h-24 object-contain'} alt="Previous" />
                                </button>
                                <button className={`swiper-button image-swiper-button-next__ absolute z-10 right-0`}>
                                    <img src={nextIcon} className={'w-fit h-24 object-contain'} alt="Next" />
                                </button>

                                <div className={'px-3 py-1 rounded-full text-white bg-[#404040] absolute top-3 left-3 z-10 text-sm font-semibold '}>-{product?.discount}%</div>
                                <Swiper
                                    style={{
                                        "--swiper-pagination-color": "#404040",
                                        "--swiper-pagination-bullet-size": "10px",
                                    }}
                                    navigation={{
                                        nextEl: ".image-swiper-button-next__",
                                        prevEl: ".image-swiper-button-prev__",
                                        disabledClass: "swiper-button-disabled",
                                    }}
                                    pagination={{ clickable: true }}
                                    modules={[Navigation, Pagination, Autoplay]}
                                    speed={1000}
                                    loop={true}
                                    className={'w-full h-full bg-[#f2f2f2]'}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                >
                                    {covers?.map((value, index) => (
                                        <SwiperSlide className={'w-full h-full '} key={index}>
                                            <img src={"https://api.osonexpress.uz/" + value} className={'w-full h-full object-contain'} alt={`Slide ${index + 1}`} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <div className={'w-full h-auto flex flex-col items-start gap-3 my-3'}>
                                {/*<span className={'text-2xl font-semibold'}>{product.title}</span>*/}
                                <div className={'w-full grid grid-cols-3 justify-center items-center content-center'}>
                                    {product.recommendedProducts?.map((value, index) => (
                                        <div key={index} className="flex justify-center">
                                            <div className="w-32 h-36 border rounded">
                                                <img src={"https://api.osonexpress.uz/"+ value.cover} className="w-full h-full object-cover" alt={value.title} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Правая сторона */}
            <div className={`w-[45%] h-full relative`}>
                <div className={style.productInfoWrapper}>
                    <div className={'h-auto w-full flex flex-col items-start p-2'}>
                        <div className={'text-2xl font-bold '}>{name}</div>
                        {/*<div className={'text-xl font-bold opacity-50 '}>{123}</div>*/}

                        <ul className={'list-disc mt-2 '}>
                            {product.information?.benefits?.map((value, index) => (
                                <li key={index} className={'list-disc text-sm font-semibold mt-2'}> • {value}</li>
                            ))}
                        </ul>

                        <div className={'w-full h-0.5 bg-[#f2f2f2] my-2'}></div>

                        {/*<p className={'text-justify px-1 text-sm font-semibold'}>{product?.description}</p>*/}

                        <div className={'text-xl opacity-50 font-bold mt-3'}>
                            {product.information?.per_100_gr_title}
                        </div>

                        <div className={'w-full flex justify-between items-end'}>
                            {product?.title?.map((value, index) => (
                                <div key={index} className={'flex flex-col items-start gap-1'}>
                                    <div className={'text-xl font-semibold'}>{value}</div>
                                    <div className={'text-sm font-bold opacity-50'}>{product?.badge[index]}</div>
                                </div>
                            ))}
                        </div>

                        <div className={'w-full h-0.5 bg-[#f2f2f2] my-1'}></div>

                        <div className={'text-xl opacity-50 font-bold mt-3'}>Maxsulot haqida</div>
                        <p className={'text-justify px-1 text-sm font-semibold'}>{product?.description}</p>

                        <div className={'text-xl opacity-50 font-bold mt-3 '}>Saqlanish muddati</div>
                        <div className={'font-semibold'}>{product?.best_b_date}</div>

                        <div className={'text-xl opacity-50 font-bold mt-3 '}>Производитель</div>
                        <div className={'font-semibold'}>{product?.made_in}</div>
                    </div>
                </div>

                <div className={`${isActive ? 'bg-red-600' : 'bg-red-500'} w-full h-16 duration-300 px-4 mt-2 rounded-xl flex items-center justify-center gap-3 active:translate-y-0.5 duration-300 absolute bottom-3`}>
                    {isActive ? (
                        <div className={'w-full h-full justify-between flex items-center gap-3 text-white select-none duration-300'}>
                            <FaMinus onClick={handleDecrement} className={'text-xl'} />
                            <span className={'text-xl font-bold flex items-center gap-1 line-clamp-1 relative h-full'}>
                                {myCount > 0 ? <div>{myCount}x</div> : ``}
                                <div>{product.price} so'm</div>
                            </span>
                            <CgMathPlus onClick={handleIncrement} className={'text-xl'} />
                        </div>
                    ) : (
                        <div onClick={handleIncrement} className={'w-full flex items-center justify-center gap-3 select-none text-white '}>
                            {product.minus ? <span className={'line-through text-white/60 font-bold text-xs line-clamp-1 '}>{product.minus} so'm</span> : ``}
                            <span className={'text-xl font-bold flex items-center gap-1 line-clamp-1'}>{price} so'm <CgMathPlus className={'text-xl'} /></span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
