import React, { useState } from 'react';
import { Button, Drawer, Form, Input, InputNumber, message, Select, Switch, Collapse } from 'antd';
import { SiCkeditor4 } from "react-icons/si";
import SelectedProductContent from "./SelectedProductContent";
import Search from "antd/es/input/Search";
import { Option } from "antd/es/mentions";

const { Panel } = Collapse;

const DevineDescription = ({
                               languages,
                               setIsIcon,
                               handleSearch,
                               description,
                               setDescription,
                               cover,
                               brands,
                               setBrandId,
                               brandId,
                               setSelectedPath,
                               iconsHandler,
                               topIcons,
                               covers,
                               isIcon,
                               filteredImages
                           }) => {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [composition, setComposition] = useState({ t: '', b: '' });
    const [expiration, setExpiration] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [additionalDescription, setAdditionalDescription] = useState('');
    const [minus, setMinus] = useState('');
    const [discount, setDiscount] = useState('');
    const [name, setName] = useState('Maxulot');
    const [price, setPrice] = useState(0);

    const openDrawer = () => setIsDrawerVisible(true);
    const closeDrawer = () => setIsDrawerVisible(false);

    const nameOnChange = (e) => setName(e.target.value);
    const priceOnChange = (value) => setPrice(value);

    const handleAddDescriptionPart = (part) => {
        setDescription(prev => {
            // Удаляем старую часть %d, если она есть
            let updatedDescription = prev.replace(/%d [^/]* \/ /, '');
            // Добавляем новую часть и перемещаем %d в конец
            updatedDescription += part;
            if (additionalDescription) {
                updatedDescription += `%d ${additionalDescription} / `;
            }
            return updatedDescription;
        });
    };

    const handleAddComposition = () => {
        if (composition.t && composition.b) {
            const newDescription = `%t ${composition.t} / %b ${composition.b} / `;
            handleAddDescriptionPart(newDescription);
        }
        setComposition({ t: '', b: '' });
    };

    const handleAddExpiration = () => {
        if (expiration) {
            const newDescription = `%x ${expiration} / `;
            handleAddDescriptionPart(newDescription);
        }
        setExpiration('');
    };

    const handleAddManufacturer = () => {
        if (manufacturer) {
            const newDescription = `%m ${manufacturer} / `;
            handleAddDescriptionPart(newDescription);
        }
        setManufacturer('');
    };

    const handleAddMinus = () => {
        if (minus) {
            const newDescription = `%&min ${minus} / `;
            handleAddDescriptionPart(newDescription);
        }
        setMinus('');
    };

    const handleAddDiscount = () => {
        if (discount) {
            const newDescription = `%*disc ${discount} / `;
            handleAddDescriptionPart(newDescription);
        }
        setDiscount('');
    };

    const handleAddAdditionalDescription = () => {
        if (additionalDescription) {
            setDescription(prev => {
                let updatedDescription = prev.replace(/%d [^/]* \/ /, '');
                updatedDescription += `%d ${additionalDescription} / `;
                return updatedDescription;
            });
        }
        setAdditionalDescription('');
    };

    return (
        <div>
            <Button type="primary bg-blue-300" onClick={openDrawer} icon={<SiCkeditor4 />}>
                Asosiy redaktorga o`tish
            </Button>

            <Drawer
                title="Редактор описания"
                width="100%"
                onClose={closeDrawer}
                visible={isDrawerVisible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <div className="flex w-full h-full">
                    <div className={'h-screen overflow-scroll'} style={{ width: '50%', padding: '20px' }}>
                        <Collapse accordion>
                            <Panel header="Asosiy ma`lumotlar" key="1">
                                <Form.Item
                                    label="Tilni tanlang"
                                    name="language_id"
                                    rules={[{ required: true, message: 'Tilni tanlang!' }]}
                                >
                                    <Select>
                                        {languages?.map((type) => (
                                            <Option key={type.id} value={type.id}>
                                                {type.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <div className={'flex flex-row flex-items-center  w-full'}>
                                    <Form.Item
                                        label="Nomi"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input the bottle name!' }]}
                                    >
                                        <Input onChange={nameOnChange} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Цена"
                                        name="price"
                                        rules={[{ type: 'number', required: true, message: 'Пожалуйста, введите цену!' }]}
                                    >
                                        <InputNumber onChange={priceOnChange} type="number" />
                                    </Form.Item>
                                </div>

                                <p className={'font-bold text-blue-700 text-4xl'}> Brandni Tanlang</p>
                                <div className={'flex flex-row flex-wrap rounded border-2 border-blue-700 p-1 overflow-scroll h-[200px] mb-2'}>
                                    {brands.map(value => (
                                        <div className={'flex flex-col'} key={value.id}>
                                            <img
                                                onClick={() => setBrandId(value.id)}
                                                src={`https://api.osonexpress.uz/${value.logo}`}
                                                className={`w-14 h-14 flex cursor-pointer m-1  ${brandId === value.id ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800' : ''}`}
                                                alt={value.name}
                                            />
                                            <p className={`text-[12px] font-bold ${brandId === value.id ? 'text-white duration-200 bg-blue-800 rounded-lg' : ''} text-blue-700 text-center`}>
                                                {value.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Panel>

                            <Panel header="Maxsulot haqida" key="2">
                                <h3>Редактировать описание</h3>

                                <div className="mb-4">
                                    <h4>Состав</h4>
                                    <Input
                                        placeholder="Значение (например, 30г)"
                                        value={composition.t}
                                        onChange={(e) => setComposition({ ...composition, t: e.target.value })}
                                        className="mb-2"
                                    />
                                    <Input
                                        placeholder="Описание (например, белки)"
                                        value={composition.b}
                                        onChange={(e) => setComposition({ ...composition, b: e.target.value })}
                                    />
                                    <Button onClick={handleAddComposition} className="mt-2">
                                        Добавить состав
                                    </Button>
                                </div>
                                <div className="mb-4">
                                    <h4>Срок годности</h4>
                                    <Input
                                        placeholder="Срок годности (например, 9 месяцев)"
                                        value={expiration}
                                        onChange={(e) => setExpiration(e.target.value)}
                                    />
                                    <Button onClick={handleAddExpiration} className="mt-2">
                                        Добавить срок годности
                                    </Button>
                                </div>
                                <div className="mb-4">
                                    <h4>Производитель</h4>
                                    <Input
                                        placeholder="Производитель (например, ЗАО Мясная Галерея)"
                                        value={manufacturer}
                                        onChange={(e) => setManufacturer(e.target.value)}
                                    />
                                    <Button onClick={handleAddManufacturer} className="mt-2">
                                        Добавить производителя
                                    </Button>
                                </div>
                                <div className="mb-4">
                                    <h4>Описание</h4>
                                    <Input.TextArea
                                        placeholder="Описание продукта"
                                        value={additionalDescription}
                                        onChange={(e) => setAdditionalDescription(e.target.value)}
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                    <Button onClick={handleAddAdditionalDescription} className="mt-2">
                                        Добавить описание
                                    </Button>
                                </div>

                                <div className="mb-4">
                                    <h4>Минус</h4>
                                    <Input
                                        placeholder="Минус (например, 5%)"
                                        value={minus}
                                        onChange={(e) => setMinus(e.target.value)}
                                    />
                                    <Button onClick={handleAddMinus} className="mt-2">
                                        Добавить минус
                                    </Button>
                                </div>

                                <div className="mb-4">
                                    <h4>Скидка</h4>
                                    <Input
                                        placeholder="Скидка (например, 10%)"
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                    <Button onClick={handleAddDiscount} className="mt-2">
                                        Добавить скидку
                                    </Button>
                                </div>
                            </Panel>

                            <Panel header="Muqova va iconkalar" key="3">
                                <div className={'flex flex-row flex-wrap'}>
                                    <Search
                                        placeholder={'Kategoriya va nomi bo`yicha izlash '}
                                        style={{ width: '100%' }}
                                        onSearch={handleSearch}
                                    />
                                </div>
                                <div className={'flex flex-row flex-wrap mt-4 justify-center items-center mb-2 ml-2 w-full'}>
                                    <p className={'font-bold text-[18px] text-blue-700'}> Muqova va Iconkani qo`shish</p>

                                    <Switch
                                        onChange={() => setIsIcon(!isIcon)}
                                        checkedChildren={'Iconkalar'}
                                        unCheckedChildren={'Muqovalar'}
                                        className={'bg-green-600 ml-2'}
                                    />
                                    {isIcon ? (
                                        <p className={'font-bold text-[24px] text-blue-700 ml-8 text-center'}> Iconka</p>
                                    ) : (
                                        <p className={' text-[24px]  ml-8 font-bold text-green-700'}> Muqova</p>
                                    )}
                                </div>
                                <div className={'flex flex-row flex-wrap mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>
                                    {filteredImages.map(value => (
                                        <div key={value.id} className={'flex flex-col ml-1'}>
                                            <img
                                                onClick={() => {
                                                    setSelectedPath(value.id);
                                                    message.success(value.path);
                                                    iconsHandler(value.path);
                                                }}
                                                src={`https://api.osonexpress.uz/${value.path}`}
                                                className={`w-20 h-20 flex cursor-pointer m-1 ${
                                                    !isIcon
                                                        ? topIcons.includes(value.path)
                                                            ? 'text-white duration-200 border-green-700 border-2 rounded-lg'
                                                            : 'text-green-700'
                                                        : covers.includes(value.path)
                                                            ? 'text-white duration-200 border-blue-700 border-2 rounded-lg'
                                                            : 'text-blue-700'
                                                }`}
                                                alt={value.name}
                                            />
                                            <p
                                                className={`text-[12px] font-bold ${
                                                    !isIcon
                                                        ? topIcons.includes(value.path)
                                                            ? 'text-white duration-200 bg-green-800 rounded-lg'
                                                            : ''
                                                        : covers.includes(value.path)
                                                            ? 'text-white duration-200 bg-blue-800 rounded-lg'
                                                            : ''
                                                } text-blue-700 text-center`}
                                            >
                                                {value.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Panel>
                        </Collapse>
                    </div>

                    <div style={{ width: '50%', padding: '20px', backgroundColor: '#f7f7f7', overflowY: 'auto' }}>
                        <h3>Clientda tahminan ko`rinishi</h3>
                        <SelectedProductContent name={name} productData={description} covers={cover} price={price} />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default DevineDescription;
