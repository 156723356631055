import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";
import {addIcons, getIcons, updateIcons} from "../../../api/Icons_api";
import {getImages} from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import {addBrands, getBrands, showBrands, updateBrands} from "../../../api/Brands_api";

export default function Brands() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);

    const [filteredImages, setFilteredImages] = useState([]);

    const [form] = Form.useForm();
    const [modalVisibleTable, setModalVisibleTable] = useState(false);
    const [selectedImageTable, setSelectedImageTable] = useState('');

    const handleImageClick = (imageUrl) => {
        setSelectedImageTable(imageUrl);
        setModalVisibleTable(true);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchBottles()
        fetchImagees()

    }, [])

    const fetchImagees = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const fetchBottles = async () => {
        setLoading(true);
        try {
            const data = await getBrands();
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit) {
                values.logo = selectedPath
                await updateBrands(selectedBottles.id, values);
                setBottles(bottles)
                message.success("Brand Serverga muvoffaqiyatli o`zgardi")
                await fetchBottles()
            } else {
                values.logo = selectedPath
                console.log(values.icon)
                await addBrands(values);
                setBottles(bottles)
                message.success("Brand Serverga muvoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'logo',
            render: (record) => {
                return <img onClick={()=>handleImageClick(`https://api.osonexpress.uz/${record}`)} src={`https://api.osonexpress.uz/${record}`} className={'w-20 h-20 object-cover cursor-pointer'}/>
            },
            dataIndex: 'logo',
            key: 'logo',
        },

        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showBrands(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined/>
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Brandni o`chirish "
                        description="Haqiqatdan o`chirishni xohlaysizmi ?"
                        onConfirm={async () => {

                            await deleteLanguage(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="Ha"
                        cancelText="Yo`q"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'logo',
            dataIndex: 'logo',
            key: 'logo',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                value.img_category.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {

            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.Price.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const search = (value) => {
        message.info(value)
    }
    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Iconkalar
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Icon qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={() => {
                            handleClick()
                        }}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel/>
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div
                            className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search
                        </div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Iconka" open={isModalOpen || edit} okButtonProps={confirmButtonProps}
                   onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Iconka qo`shish"
                    initialValues={{remember: true, cashbackPercent: 0}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle name!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>




                    <div className={'flex flex-row flex-wrap'}>

                        <Search
                            placeholder={'Kategoriya va nomi bo`yicha izlash '}
                            style={{width:'100%'}}
                            onSearch={handleSearch}
                        />
                    </div>

                    <div className={'flex flex-row flex-wrap  mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>

                        {
                            filteredImages.map(value =><div className={'flex flex-col'}>
                                <img onClick={() => {
                                    setSelectedPath(value.path)
                                    message.success(value.path)
                                }
                                } src={`https://api.osonexpress.uz/${value.path}`}
                                     className={`w-20 h-20 flex cursor-pointer m-1  ${selectedPath === value.path ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800':''}`}/>

                                <p className={`text-[12px] font-bold  ${selectedPath === value.path ? 'text-white duration-200 bg-blue-800 rounded-lg' :''} text-blue-700  text-center`}>{value.name}</p>
                            </div>)
                        }
                    </div>


                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer title="Bottle" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>


                </div>


            </Drawer>
        </div>
        <Modal
            visible={modalVisibleTable}
            onCancel={() => setModalVisibleTable(false)}
            footer={null}
        >
            {/* Отображение выбранного изображения в модальном окне */}
            <img src={selectedImageTable} alt="Selected Image" style={{ width: '100%' }} />
        </Modal>
    </div>
}
